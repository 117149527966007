import React from "react"

import Layout from "../components/Layout"

export default function Products() {
  return (
    <Layout title="Products">
      <div className="abouts segments-page default-container">
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>
                  SINECCH<sup>&trade;</sup>
                </h2>
                <div className="social-link"></div>
                <p>
                  SINECCH<sup>&trade;</sup> is a homeopathic regimen of Arnica
                  Montana. It is made by Alpine Pharmaceuticals out of San
                  Ramon, California, USA. It has been shown in double-blind
                  research studies to reduce swelling and bruising after surgery
                  in a statistically significant manner. SINECCH
                  <sup>&trade;</sup> was developed in 1997 and has been used in
                  hundreds of thousands of plastic surgeries all over the world.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Skincare and Cosmetics</h2>
                <div className="social-link"></div>
                <p>
                  We carry an extensive line of skincare and cosmetics available
                  for private label. We work with the world renown skincare
                  manufacturer Cosmetic Solutions, LLC. Cosmetic Solutions is an
                  FDA Registered, cGMP Compliant and ISO 22716 Certified
                  Personal and Skincare Contract Manufacturer in Boca Raton,
                  Florida, USA. They have more than 25 years experience and are
                  an internationally recognized expert in the research,
                  development and production of technologically advanced
                  skincare products.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="about">
            <div className="content b-shadow">
              <div className="text">
                <h2>Minoxidil Hair Regrowth</h2>
                <div className="social-link"></div>
                <p>
                  Commonly referred to as Rogaine, Minoxidil is an effective,
                  FDA-approved medication for the treatment of hair loss. It
                  typically comes in either a foam or a topical solution. We
                  carry the brands Kirkland and Equate and offer the following
                  variations:
                </p>
                <ul>
                  <li>
                    Kirkland Signature Hair Regrowth Treatment Extra Strength
                    for Men, 5% Minoxidil Topical Solution
                  </li>
                  <li>
                    Kirkland Signature Hair Regrowth Treatment for Men, 5%
                    Minoxidil Foam
                  </li>
                  <li>
                    Equate Women's 2% Minoxidil Topical Solution for Hair
                    Regrowth
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
